import React, { FC } from "react"

import { Edit, Visibility, VisibilityOff } from "@mui/icons-material"
import { Box, Grid, Tooltip, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import api from "api/api"
import clipboard from "assets/icons/clipboard.svg"
import Stock from "components/stock"
import { useReduxDispatch } from "store"
import actions from "store/actions"
import { Product as ProductType } from "types/common.types"
import { formatPrice } from "utils/format.utils"

import * as S from "./Product.style"

interface ProductProps {
  product: ProductType
  isProductData?: boolean
}

const Product: FC<ProductProps> = ({ product, isProductData }) => {
  const { reference, image, name, totalPrice, type, soldBy, stock, warningLevel, visible } = product
  const navigate = useNavigate()
  const intl = useIntl()
  const dispatch = useReduxDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const copyToClipboard = () => {
    navigator.clipboard.writeText(reference)
    enqueueSnackbar(intl.formatMessage({ id: "snackbar.success.product.copy" }))
  }

  const updateVisibility = async () => {
    dispatch(actions.products.loading())
    try {
      await api.products.create({ ...product, visible: !product.visible, image: null })
    } finally {
      dispatch(actions.products.refresh())
    }
  }

  return (
    <S.Container container display="flex" width="100%" justifyContent="space-between">
      <Grid item container xs={5}>
        <Box display="flex" flexDirection="column" p={1} justifyContent="center">
          <S.Image src={image} />
        </Box>
        <Box display="flex" flexDirection="column" p={1} justifyContent="center">
          <Typography variant="h6">{name}</Typography>
          <S.Reference>
            <FormattedMessage id="product.reference" values={{ reference }} />
            <img src={clipboard} style={{ cursor: "pointer", height: "13px" }} onClick={copyToClipboard} />
          </S.Reference>
        </Box>
      </Grid>
      <Grid item container xs={7} justifyContent="space-between">
        <Grid item container xs={4} p={1} justifyContent="center" flexDirection="column">
          <S.Price>{formatPrice(intl, totalPrice)} HT</S.Price>
          <div>{type}</div>
          <div>
            <FormattedMessage id="product.soldBy" values={{ soldBy }} />
          </div>
        </Grid>
        <Grid item container xs={4} p={1} justifyContent="center" alignItems="center" flexDirection="column">
          <Stock stock={stock} warningLevel={warningLevel} />
          <S.Reference>
            <FormattedMessage id="product.warningLevel" values={{ warningLevel }} />
          </S.Reference>
        </Grid>
        {!isProductData && (
          <Grid item container xs={4} p={1} justifyContent="flex-end" alignItems="center">
            <S.Icon onClick={updateVisibility}>
              <Box pr={2}>
                <Tooltip arrow title={intl.formatMessage({ id: `screen.product.visibility.${visible ? "on" : "off"}` })}>
                  {!visible ? <VisibilityOff color="inherit" fontSize="inherit" /> : <Visibility color="inherit" fontSize="inherit" />}
                </Tooltip>
              </Box>
            </S.Icon>
            <S.Icon>
              <Tooltip arrow title={intl.formatMessage({ id: "screen.product.edit" })}>
                <Edit
                  color="inherit"
                  fontSize="inherit"
                  onClick={() => {
                    navigate(intl.formatMessage({ id: "route.product.create" }))
                    dispatch(actions.products.setProduct(product))
                  }}
                />
              </Tooltip>
            </S.Icon>
          </Grid>
        )}
      </Grid>
    </S.Container>
  )
}

export default Product

import styled from "@emotion/styled"

import { COLORS } from "assets/styles/colors"

type ContainerProps = {
  isOver: boolean,
  isProductData?: boolean
}

export const Container = styled.div`
  border: 1px solid ${(props: ContainerProps) => (props.isOver ? COLORS.PRIMARY : COLORS.GREY_BG)};
  cursor: ${(props: ContainerProps) => (props.isProductData ? "pointer" : "move")};;
`
